import { template as template_8a7faa8bb94546c8967a131d7041017e } from "@ember/template-compiler";
const EmptyState = template_8a7faa8bb94546c8967a131d7041017e(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
