import { template as template_0c40beda80b6465f8472667d6bef6ed4 } from "@ember/template-compiler";
const FKLabel = template_0c40beda80b6465f8472667d6bef6ed4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
