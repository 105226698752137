import { template as template_c94b03c5a10542769f599a6cbe036f93 } from "@ember/template-compiler";
const WelcomeHeader = template_c94b03c5a10542769f599a6cbe036f93(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
